<template>
  <div>
    <tw-header :title="$t('BreadCrumbs.Export Customs Clearance List')"></tw-header>
    <div class="tw_container">
      <tw-breadcrumbs :pages="breadcrumbs" style="margin-bottom: 40px" />
      <tw-entity-buttons />

      <el-form class="search_unit compact" ref="searchUnit" :model="searchForm" label-position="top">
        <div class="row">
          <el-form-item :label="$t('Label.Trading ID / Flow ID')" prop="tradingFlowId">
            <el-input type="text" v-model.trim="searchForm.tradingFlowId" class="trading-flow" placeholder="Enter keyword"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Trading Name')" prop="tradingName">
            <el-input type="text" v-model="searchForm.tradingName" placeholder="Enter keyword"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Flow Name')" prop="flowName">
            <el-input type="text" v-model="searchForm.flowName" placeholder="Enter keyword"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Process')" prop="processTypes">
            <el-select v-model="searchForm.processTypes" :class="{multiple_selected: multipleSelect(searchForm.processTypes)}" placeholder="Select" multiple collapse-tags clearable>
              <el-option
                v-for="process in processes"
                :key="process.name"
                :label="process.name"
                :value="process.processType"
              >
                <span>{{process.name}}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('Label.Process User Memo')" prop="processUserMemo">
            <el-input type="text" v-model="searchForm.processUserMemo" placeholder="Enter keyword"></el-input>
          </el-form-item>
          <tw-button type="secondary" size="small" class="toggle_button" :class="{ open: openForm }" @click="openToggle">
            <img src="@/assets/images/icons/view_all.svg">
          </tw-button>
        </div>

        <transition
          name="content"
          @enter="enter"
          @after-enter="afterEnter"
          @leave="leave"
          @after-leave="afterLeave"
        >
          <div v-show="openForm">
            <div class="row">
              <el-form-item :label="$t('Label.Invoice No')" prop="invoiceNo">
                <el-input type="text" v-model="searchForm.invoiceNo" placeholder="Enter keyword"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Invoice Date')" prop="invoiceDate">
                <el-date-picker
                  v-model="searchForm.invoiceDate"
                  type="daterange"
                  range-separator="to"
                  format="yyyy-MM-dd"
                  :default-value="defaultDate"
                  @change.native="onDateRangeChange"
                  start-placeholder="Start date"
                  end-placeholder="End date">
                </el-date-picker>
              </el-form-item>
              <el-form-item :label="$t('Label.Section(From)')" prop="fromSectionIds">
                <TwSelectSection v-model="searchForm.fromSectionIds" :sections="fromSections" />
              </el-form-item>
              <el-form-item :label="$t('Label.Section(To)')" prop="toSectionIds">
                <TwSelectSection v-model="searchForm.toSectionIds" :sections="toSections" />
              </el-form-item>
            </div>
            <div class="row">
              <el-form-item :label="$t('Label.S/I NO')" prop="siNo">
                <el-input type="text" v-model="searchForm.siNo" placeholder="Enter keyword"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Main Goods')" prop="contractGroupOfGoods">
                <el-input type="text" v-model="searchForm.contractGroupOfGoods" placeholder="Enter keyword"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Label.Status')" prop="status">
                <el-select v-model="searchForm.status" :class="{multiple_selected: multipleSelect(searchForm.status)}" placeholder="Select" multiple collapse-tags clearable>
                  <el-option
                    v-for="status in PROCESS_PROGRESS_STATUS_VARIABLES"
                    :key="status.code"
                    :label="status.label"
                    :value="status.code">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('Label.Linking Status')" prop="permitLinkFlg">
                <el-select v-model="searchForm.permitLinkFlg" placeholder="Select" collapse-tags clearable>
                  <el-option
                    v-for="status in PERMIT_LINK_FLG_VARIABLES"
                    :key="status.code"
                    :label="status.label"
                    :value="status.code">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('Label.Amendment')" prop="amendmentFlg" style="margin-right: 40px">
                <el-select v-model="searchForm.amendmentFlg" placeholder="Select" collapse-tags clearable>
                  <el-option
                    v-for="status in AMENDMENT_FLG_VARIABLES"
                    :key="status.code"
                    :label="status.label"
                    :value="status.code">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('Label.Cargo Tracking')" prop="exCargoTrackingFlg">
                <el-select v-model="searchForm.exCargoTrackingFlg" placeholder="Select" collapse-tags clearable>
                  <el-option
                    v-for="status in CARGO_TRACKING_FLG_VARIABLES"
                    :key="status.code"
                    :label="status.label"
                    :value="status.code">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="row">
              <el-form-item class="max-content" :label="$t('Label.Create Datetime')" prop="processCreateDateTime">
                <el-date-picker
                  v-model="searchForm.processCreateDateTime"
                  type="datetimerange"
                  range-separator="to"
                  format="yyyy-MM-dd HH:mm"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00', '23:59:59']"
                  start-placeholder="Start datetime"
                  end-placeholder="End datetime">
                </el-date-picker>
              </el-form-item>
              <el-form-item class="max-content" :label="$t('Label.Update Datetime')" prop="processUpdateDateTime">
                <el-date-picker
                  v-model="searchForm.processUpdateDateTime"
                  type="datetimerange"
                  range-separator="to"
                  format="yyyy-MM-dd HH:mm"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00', '23:59:59']"
                  start-placeholder="Start datetime"
                  end-placeholder="End datetime">
                </el-date-picker>
              </el-form-item>
            </div>
          </div>
        </transition>

        <div class="row drop space_between">
          <div>
            <tw-button
              type="secondary"
              size="medium"
              icon="search"
              @click="search"
            >
              Search
            </tw-button>
            <tw-button
              type="default"
              size="medium"
              @click="clear"
              style="margin: 8px 16px 16px;"
            >
              Clear
            </tw-button>
          </div>

          <el-dropdown trigger="click" placement="bottom" @command="onDropDownClick" :tabindex="-1">
            <tw-button class="menu_button" type="secondary" size="small" icon="menu">Menu</tw-button>
            <el-dropdown-menu class="nowrap">
              <el-dropdown-item command="tsv">{{$t('Label.TSV Download')}}</el-dropdown-item>
              <el-dropdown-item command="openTableSettingDialog">{{ $t('Label.Column Preference') }}</el-dropdown-item>
              <el-dropdown-item command="changeTableWidth">{{ tableMode === 'show' ? $t('Label.Column Width') : $t('Label.Complete Width Edit Mode') }}</el-dropdown-item>
              <el-dropdown-item command="resetTableSetting">{{ $t('Label.Reset Column') }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-form>

      <tw-table-system :schemas="showSchema" :items="items" :border="tableMode === 'changeWidth'" :pageSize="limit" :totalCount="totalCount" :offset="offset" :selection="selection" :serverPagination="true" @row-click="rowClick" @row-dbclick="rowDbClick" @paging="onPaging" @change-column-width="changeColumnWidth" />
    </div>
    <tw-table-column-setting-dialog
      :visible.sync="isOpenTableSettingDialog"
      :full-schemas="schemas"
      :show-schemas.sync="filteredSchemas"
      @close="updateColumnSetting"
    ></tw-table-column-setting-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import { EXPORT_CUSTOMS_SEARCH_SORT_TARGET, DEFAULT_PAGENATION, ENTITY_TYPE, PERMIT_LINK_FLG_VARIABLES, AMENDMENT_FLG_VARIABLES, CARGO_TRACKING_FLG_VARIABLES } from 'lib-tw-common';
import { $api } from '@/store/ApiClient';
import mixinEntityList from '@/utils/mixinEntityList.js';
import mixinEntity from '@/utils/mixinEntity.js';
import mixinTableSetting from '@/utils/mixinTableSetting.js';
import { collapseTransition } from '@/utils/nextFrame';
import { clearSearchForm, formatUtcDate } from '@/utils/searchUtil.js';
import schemas from '@/dictionaries/listExCustomsClearanceSchema.json';
import TwTableColumnSettingDialog from '@/components/organisms/TwTableColumnSettingDialog.vue';

const ENTITY_NAME = 'EX_CUSTOMS';

export default {
  name: 'ListExCustomsClearance',
  mixins: [mixinEntityList, collapseTransition, mixinEntity, mixinTableSetting],
  components: {
    TwTableColumnSettingDialog
  },
  data() {
    return {
      entityCode: ENTITY_TYPE.EX_CUSTOMS,
      schemas: schemas,
      items: null,
      searchForm: {
        tradingFlowId: '',
        tradingName: '',
        flowName: '',
        invoiceNo: '',
        invoiceDate: '',
        fromSectionIds: [],
        toSectionIds: [],
        siNo: '',
        contractGroupOfGoods: '',
        status: [],
        permitLinkFlg: null,
        amendmentFlg: null,
        exCargoTrackingFlg: null,
        processTypes: [],
        processUserMemo: '',
        processUpdateDateTime: [],
        processCreateDateTime: [],
      },
      limit: DEFAULT_PAGENATION.LIMIT,
      offset: 0,
      sort: null,
      count: 0,
      totalCount: 0,
      pageCount: 0,
      lastQuery: {},
      PERMIT_LINK_FLG_VARIABLES,
      AMENDMENT_FLG_VARIABLES,
      CARGO_TRACKING_FLG_VARIABLES,
      isOpenTableSettingDialog: false,
      tableId: 'listExCustomsClearance'
    };
  },
  computed: {
    breadcrumbs() {
      return [{ label: this.$t('BreadCrumbs.Dashboard'), to: '/' }, { label: this.$t('BreadCrumbs.Export Customs Clearance List') }];
    }
  },
  created() {
    const queries = this.$store.getters.getQueries(ENTITY_NAME);
    if (queries) {
      this.searchForm = queries;
    }
    this.fetch();
    this.processes = this.getProcessesByEntity(ENTITY_NAME);
  },
  methods: {
    // POSTパラメータを生成します
    transformQueries() {
      const f = this.searchForm;
      const queries = {
        fromSectionIds: f.fromSectionIds,
        toSectionIds: f.toSectionIds,
        invoiceDateStartDate: this.getYMDStartDate(f.invoiceDate),
        invoiceDateEndDate: this.getYMDEndDate(f.invoiceDate),
        tradingId: this.getTradingFlowId(f.tradingFlowId)[0],
        tradingFlowId: this.getTradingFlowId(f.tradingFlowId)[1],
        tradingName: f.tradingName,
        flowName: f.flowName,
        invoiceNo: f.invoiceNo,
        siNo: f.siNo,
        contractGroupOfGoods: f.contractGroupOfGoods,
        processProgressStatus: this.getProcessProgressStatus(f.status),
        processIds: f.processTypes ? _.flatten(_.map(f.processTypes, item => this.getProcessIdsByProcessType(item))) : [],
        processUserMemo: f.processUserMemo,
        permitLinkFlg: f.permitLinkFlg,
        amendmentFlg: f.amendmentFlg,
        exCargoTrackingFlg: f.exCargoTrackingFlg,
        processUpdateDateTimeFrom: f.processUpdateDateTime ? formatUtcDate(f.processUpdateDateTime[0]) : null,
        processUpdateDateTimeTo: f.processUpdateDateTime?.[1] ? formatUtcDate(f.processUpdateDateTime[1]).replace('00Z', '59Z') : null,
        processCreateDateTimeFrom: f.processCreateDateTime ? formatUtcDate(f.processCreateDateTime[0]) : null,
        processCreateDateTimeTo: f.processCreateDateTime?.[1] ? formatUtcDate(f.processCreateDateTime[1]).replace('00Z', '59Z') : null,
      };

      return {
        ...queries,
        initialViewFlag: this.initialFlag(queries),
        limit: this.limit,
        offset: this.offset,
        sort: this.sort
      };
    },
    fetch(queries) {
      this.cancelRequestSources.forEach(tag => {
        $api.cancelRequests(tag);
      });
      // bff_ec_1 輸出通関一覧検索BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-transaction-bff-api',
          apiCode: 'get_/v1/export-customs/search',
          query: queries || this.transformQueries()
        },
        tag: this.pushCancelTag()
      };

      this.lastQuery = _.cloneDeep(params.lslConfig.query);

      $api
        .request(params)
        .then(res => {
          this.items = res.exportCustoms;
          this.totalCount = res.totalCount;
        })
        .catch(err => {
          if (err.isCanceled) {
            return;
          }
          this.items = [];
          this.$store.dispatch('SHOW_ALERT', err.message);
        });
    },
    search() {
      this.offset = 0;
      this.$store.commit('SET_QUERIES', { key: ENTITY_NAME, queries: _.cloneDeep(this.searchForm) });
      this.fetch();
    },
    clear() {
      this.searchForm = clearSearchForm(this.searchForm);
      this.$store.commit('SET_QUERIES', { key: ENTITY_NAME, queries: _.cloneDeep(this.searchForm) });
    },
    onPaging({ target, sortType, currentPage }) {
      if (target && sortType) {
        const key = _.get(_.find(this.schemas, { key: target }), 'sortTarget');
        this.sort = {
          target: [EXPORT_CUSTOMS_SEARCH_SORT_TARGET[key] || target],
          sortType: [sortType]
        };
      } else {
        this.sort = null;
      }

      this.offset = currentPage * this.limit - this.limit;
      const queries = {
        ...this.lastQuery,
        limit: this.limit,
        offset: this.offset,
        sort: this.sort
      };
      this.fetch(queries);
    },
    // TSVダウンロード用に全件取得します
    fetchAll() {
      return new Promise((resolve, reject) => {
        // bff_ec_1 輸出通関一覧検索BFF
        const params = {
          lslConfig: {
            serviceCode: 'tw-transaction-bff-api',
            apiCode: 'get_/v1/export-customs/search',
            query: { ...this.transformQueries(), offset: undefined, limit: undefined }
          }
        };

        $api
          .request(params)
          .then(res => {
            resolve(res.exportCustoms);
          })
          .catch(err => {
            this.$store.dispatch('SHOW_ALERT', err.message);
            reject();
          });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.search_unit .max-content {
  width: max-content;
}
::v-deep .tw_table {
  background-color: #d6d6dc;
  &.el-table--border th, &.el-table--border td {
    border-right: 1px solid #BFBFCD;
  }
  & .el-table__body, & .el-table__header {     
    min-width: unset !important;
    & .gutter {
      border: none;
      background-color: #d6d6dc;
    }
  }
}
</style>
